<template>
  <v-container class="mt-4">
    <page-main
      title="Welcome to Resumation!"
      nextText="What makes a good resume"
      buttonTabIndex="6"
      eventName="nav"
      @nav="nav('/WhatMakesAGoodResume')"
    >
      <ul class="text-body-big">
        <li class="mb-4">
          <span class="font-weight-bold">What is Resumation?</span>
          <ul>
            <li>
              Resumation is your all-in-one solution for resume building and interview preparation. Acting as your personal virtual resume consultant, it leverages Artificial Intelligence (AI) to guide you through a unique questionnaire. This process not only highlights the best aspects of your experiences but also optimizes your resume content to ensure it stands out. With Resumation, you start from the ground up, enhancing your resume by integrating your existing resources for optimal results.
            </li>
          </ul>
        </li>
        <li class="mb-4">
          <span class="font-weight-bold">Why use Resumation?</span>
          <ul>
            <li>
              The process of creating a resume can be challenging, often lengthy and complex. However, a well-constructed resume is key to unlocking professional doors. That's where Resumation comes in. Our tool is designed to make the resume creation process quicker and easier, without compromising on personalization and quality. This distinct approach sets us apart from traditional methods and other tools in the market.
            </li>
          </ul>
        </li>
        <li>
          <span class="font-weight-bold">Our Vision, Mission, and Drive</span>
          <ul>
            <li>
              At Resumation, our goal is to be your go-to resource for resume building and interview prep. We are on a mission to empower job seekers and professionals to effectively articulate their value. Motivated by the belief that a resume should never be an obstacle in your career path, we are dedicated to offering a service that facilitates your progress toward professional goals.
            </li>
          </ul>
        </li>
      </ul>
    </page-main>
  </v-container>
</template>

<script>
export default {
  name: "Welcome",
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    }
  }
};
</script>

<style scoped>
.container ul ul {
  list-style-type: disc;
}
</style>